<!-- form input field -->

<mat-form-field>
  <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.STATUS' | translate }}</mat-label>
  <mat-select #statusFormField (selectionChange)="openDialog($event)" [value]="selectedStatus" [disabled]="isDisabled">
    <mat-option *ngFor="let status of allowedStates"
                [value]="status">{{ 'ORDER_OVERVIEW.ORDER_STATUS.' + status | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>

<!--overlay-->
<ng-template #templatePortalContent>
  <div class="status-dialog">
    <h2>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.STATUS' | translate }}</h2>

    <div class="ruler"></div>

    <div class="form-fields">


      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.STATUS' | translate }}</mat-label>
        <mat-select [(value)]="selectedStatus">
          <mat-option *ngFor="let status of allowedStates"
                      [value]="status">
            {{ 'ORDER_OVERVIEW.ORDER_STATUS.' + status | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.STATUS_DIALOG.ASSIGNED_TO' | translate }}</mat-label>
        <mat-select [(value)]="assignedTo" [compareWith]="compareUser" [disabled]="!userAllowedToChangeUser">
          <mat-option *ngFor="let user of userlist"
                      [value]="user">
            {{ user.firstname + ' ' + user.lastname }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.STATUS_DIALOG.COMMENT' | translate }}</mat-label>
        <textarea [formControl]="commentControl"
                  [placeholder]="'ORDER_DETAIL.GENERAL.FORM.STATUS_DIALOG.COMMENT_PLACEHOLDER' | translate"
                  matInput
        >
      </textarea>
      </mat-form-field>
    </div>

    <div class="spacer"></div>
    <div class="ruler"></div>

    <div class="buttons">
      <button (click)="abortDialog()"
              mat-stroked-button>{{ 'ORDER_DETAIL.GENERAL.FORM.STATUS_DIALOG.CANCEL' | translate }}
      </button>
      <button (click)="closeWithSave()" color="accent"
              mat-flat-button>{{ 'ORDER_DETAIL.GENERAL.FORM.STATUS_DIALOG.SAVE' | translate }}
      </button>
    </div>

  </div>
</ng-template>
