<div class="cleaning-control">
  <mat-form-field class="test">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.TEST' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.test" [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>

  <mat-form-field class="interval">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.INTERVAL' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.interval" [disabled]="hygieneInspectionDisabled"/>
  </mat-form-field>
</div>
