<cx-file-uploader
  [multiple]="multiple"
  [files]="filesInProgress"
  (selectFile)="onFileSelected($event)"
  [endpoint]="endpoint"
  [acceptedFileTypes]="acceptedFileTypes"
  [buttonText]="buttonText"
  [manualUpload]=true
  [description]="description"
  [errorMessage]="errorMessage"
  [disabled]="uploaderDisabled"
  [heading]="heading">
</cx-file-uploader>
