<hpm-header [title]="headerTitle ?? 'ORDER_DETAIL.HEADER.NEW'">
  <mat-icon (click)="saveOrder()" class="white-medium-icon linkback" routerLink="/order">list_alt</mat-icon>
  <cx-menu class="header-menu">
    <cx-menu-item (click)="openHistory()"> {{ 'ORDER_DETAIL.MENU.HISTORY' | translate }}</cx-menu-item>
  </cx-menu>
</hpm-header>

<div class="detail-page-content container">
  @if (order) {
    <mat-tab-group [(selectedIndex)]="selectedTab">
      <mat-tab [label]="'ORDER_DETAIL.TAB.GENERAL' | translate"></mat-tab>
      <mat-tab [label]="'ORDER_DETAIL.TAB.ON_SITE_CHECK' | translate"></mat-tab>
      <mat-tab [label]="'ORDER_DETAIL.TAB.DOCUMENTS' | translate"></mat-tab>
    </mat-tab-group>
    <hpm-general-form *ngIf="selectedTab === 0" [order]="order" (orderChange)="saveOrder($event)"></hpm-general-form>
    <hpm-hygiene-inspection *ngIf="selectedTab === 1" [order]="order"
                            (orderChange)="saveOrder()"></hpm-hygiene-inspection>
    <hpm-documents *ngIf="selectedTab === 2" [order]="order" (orderChange)="saveOrder()"></hpm-documents>
  } @else if (!isLoading) {
    <div class="container">
      <cx-banner class="error-message" [message]="'ORDER_DETAIL.ERROR_MESSAGE' | translate" type="error"></cx-banner>
    </div>
  }
</div>
