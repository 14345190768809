import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Area } from '../model/area.model';
import { VisibilityController } from '../service/visibility.controller';
import { Subject, takeUntil } from 'rxjs';
import { QuestionnaireHttpService } from '../service/questionnaire-http.service';
import { AreaService } from './area.service';
import { AreaChangeDetection } from './area-change-detection.service';
import { Order } from '../../../../../order.model';

@Component({
  selector: 'hpm-area',
  templateUrl: './area.component.html',
  styleUrl: './area.component.scss',
  providers: [AreaService, AreaChangeDetection],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Input() areaName!: string;
  @Input() areaHasNotes!: boolean;
  @Input() hygieneInspectionDisabled = false;
  @Output() areaHasNotesChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  area: Area | undefined;
  visibilityController: VisibilityController = new VisibilityController();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private questionnaireHttpService: QuestionnaireHttpService,
    private areaService: AreaService,
    private areaChangeDetection: AreaChangeDetection,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadAreaByName();
    this.areaChangeDetection
      .areaHasChanged()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.setAreaNotesStatus();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadAreaByName(): void {
    if (this.order.id) {
      this.questionnaireHttpService
        .getAreaByName(this.order.id, this.areaName)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((area) => {
          this.area = area;
          this.buildVisibilityControlForSections();
          this.areaService.setArea(this.area);
          this.setAreaNotesStatus();
          this.cdr.detectChanges();
        });
    }
  }

  private setAreaNotesStatus(): void {
    if (this.area) {
      const someAreaHasNotes = this.area.content.sections.some(
        (section) => section.notes?.comment || section.notes?.qm,
      );
      this.areaHasNotesChange.emit(someAreaHasNotes);
    }
  }

  private buildVisibilityControlForSections(): void {
    if (this.area) {
      this.visibilityController = new VisibilityController(this.area);
    }
  }
}
