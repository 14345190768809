import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  Choice,
  DetailMultipleChoice,
} from '../../../model/detail-questions.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AreaChangeDetection } from '../../area-change-detection.service';

@Component({
  selector: 'hpm-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrl: './multiple-choice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MultipleChoiceComponent implements OnInit {
  @Input() hygieneInspectionDisabled = false;
  @Input() detail!: DetailMultipleChoice;

  constructor(private areaChangeDetection: AreaChangeDetection) {}

  checkBoxChanged(choice: Choice, event: MatCheckboxChange): void {
    choice.value = event.checked;
    this.areaChangeDetection.onChange();
  }

  ngOnInit(): void {
    this.ensureInputNotEmpty();
  }

  private ensureInputNotEmpty(): void {
    this.detail.choices.forEach((choice) => {
      if (choice.showTextList) {
        if (!choice.textFieldValues) {
          choice.textFieldValues = [];
        }
        if (choice.textFieldValues!.length === 0) {
          choice.textFieldValues!.push('');
        }
      }
    });
  }

  addLine(index: number, choice: Choice): void {
    choice.textFieldValues!.splice(index + 1, 0, '');
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number, choice: Choice): void {
    choice.textFieldValues!.splice(index, 1);
    this.ensureInputNotEmpty();
    this.areaChangeDetection.onChange();
  }
}
