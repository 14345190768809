import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailCleaningControl } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-cleaning-control',
  templateUrl: './cleaning-control.component.html',
  styleUrl: './cleaning-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningControlComponent {
  @Input() detail!: DetailCleaningControl;
  @Input() hygieneInspectionDisabled = false;
}
