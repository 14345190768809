import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hpm-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent {
  @Input() multiple = true;
  @Input() endpoint = '';
  @Input() acceptedFileTypes = '';
  @Input() buttonText = 'Upload';
  @Input() description = 'Upload file';
  @Input() errorMessage = 'Upload failed';
  @Input() heading = 'Upload';
  @Input() uploaderDisabled = false;
  @Output() uploadComplete = new Subject<void>();

  filesInProgress: Array<CxFileProgress> = [];

  constructor(
    private http: HttpClient,
    private snackbarService: CxSnackbarService,
    private translateService: TranslateService,
  ) {}

  onFileSelected(files: Array<File>): void {
    files.map((file) => {
      const fileInProgress = {
        file,
        //state: CxFileUploadState.InProgress,
        progress: 0,
      } as CxFileProgress;

      this.filesInProgress.push(fileInProgress);

      const formData = new FormData();
      formData.append('file', file, file.name);

      this.http.post(this.endpoint, formData).subscribe({
        complete: () => {
          this.uploadComplete.next();
          //fileInProgress.state = CxFileUploadState.Completed;
          fileInProgress.progress = 100;
          this.translateService
            .get('UPLOAD.SUCCESS')
            .subscribe((msg: string) => {
              this.snackbarService.success(msg + file.name);
            });
        },
        error: (e) => {
          //fileInProgress.state = CxFileUploadState.Error;
          this.translateService
            .get('UPLOAD.FAILURE')
            .subscribe((msg: string) => {
              this.snackbarService.error(msg + file.name);
            });
          console.error(e);
        },
      });
    });
  }
}
