import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailCleaningName } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-cleaning-name',
  templateUrl: './cleaning-name.component.html',
  styleUrl: './cleaning-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningNameComponent {
  @Input() detail!: DetailCleaningName;
  @Input() hygieneInspectionDisabled = false;
}
