<div >
  <h5>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.INVENTORY.HEADLINE" | translate }}</h5>

  <mat-form-field class="col-12">
    <mat-label>{{'ORDER_DETAIL.HYGIENE_INSPECTION.INVENTORY.HEADLINE_AREA' | translate }}</mat-label>
    <mat-select multiple [value]="getSelectedInventory()" (selectionChange)="saveSelection()" [disabled]="hygieneInspectionDisabled">
      @for (inventory of inventories; track inventory) {
        <mat-option [value]="inventory" (onSelectionChange)="toggleInventoryCheckmark($event)">
          {{ inventory.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-accordion class="col-12">
    @for (inventory of getSelectedInventory(); track inventory) {
      <mat-expansion-panel class="cx-expansion-panel col-12">
        <mat-expansion-panel-header>
        <div class="expansionHeader">
        <div class="expansionTitle"> {{ inventory.name }} </div>
          <div class="spacer"></div>
          @if(inventory.hasNotes){
            <mat-icon class="material-symbols-outlined badge"> chat_bubble </mat-icon>
          }
        </div >
        </mat-expansion-panel-header>
        <hpm-area [(areaHasNotes)]="inventory.hasNotes" [areaName]="inventory.name" [order]="order" [hygieneInspectionDisabled]="hygieneInspectionDisabled"></hpm-area>
      </mat-expansion-panel>
    }
  </mat-accordion>
</div>
