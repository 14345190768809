import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailManufacturer } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-contact',
  templateUrl: './manufacturer.component.html',
  styleUrl: './manufacturer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerComponent {
  @Input() hygieneInspectionDisabled = false;
  @Input() detail: DetailManufacturer = {
    kind: '',
    name: '',
    company: '',
  };
}
